<template>
	<section class="domain_content">
		<!-- privacy policy -->
		<div class="sub-page">
			<h1>Privacy Policy</h1>
			<p>
				This Privacy Policy for <a href="https://gbapks.org.in">https://gbapks.org.in</a> describes how and why we might access or collect your personal information when you use our services When you visit our website or any website of our sites that links to this Privacy page.
			</p>
			<p>
				We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our services.	
			</p>
			<div class="part">
				<h2>
				What personal information do we process? 
				</h2>
				<p>
					When you visit, use, or navigate our services, we may process personal information depending on how you interact with us and the services, the choices you make, and the products and features you use. 				
				</p>
				<h2>
					Do we process any sensitive personal information? 
				</h2>
				<p>
					Some of the information may be considered "special" or "sensitive" in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. We do not process sensitive personal information.
				</p>
				<h2>
					Do we collect any information from third parties? 
				</h2>
				<p>
					We do not collect any information from third parties.
				</p>
				<h2>
					How do we process your information?
				</h2>
				<p>
					We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We process your information only when we have a valid legal reason to do so.
				</p>
				<h2>
					How do we keep your information safe? 
				</h2>
				<p>
					We have adequate organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. If you need help, you can contact us.
				</p>
			</div>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Privacy Policy'
const description = 'Privacy Policies are needed for users to read about when using GB WhatsApp and visiting this site.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://gbapks.org.in/privacy/"
            }]
	},
}
</script>
  